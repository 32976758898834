import IsMobile from "src/helpers/isMobile"

function Logo() {
  return (
    <>
      {IsMobile() ? (
        <span>
          <svg
            className="header__logo header__logo-mobile"
            width="96"
            height="36"
            viewBox="0 0 96 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.114 11.7899V6.58621C11.114 4.67213 9.85494 3.63867 6.44024 3.63867C2.94335 3.63867 1.7852 4.92322 1.58345 7.00833H2.91721C3.01808 5.39992 4.11646 4.82497 6.4141 4.82497C8.83128 4.82497 9.77649 5.3235 9.77649 6.77908C9.77649 7.75431 9.40288 7.94353 6.20487 8.09637C3.96328 8.19462 1.11646 8.53668 1.11646 11.2149C1.11646 13.4529 3.33563 14.1989 5.08408 14.1989C8.42405 14.1989 9.40289 12.8597 9.85494 12.2084H9.89603C9.89603 12.9944 9.99317 13.9696 11.4652 13.9696C11.8754 13.9589 12.2835 13.9077 12.6831 13.8168V12.8597C12.429 12.8779 12.175 12.8998 11.9172 12.8998C11.3493 12.8998 11.114 12.6123 11.114 11.7899ZM9.77649 10.0432C9.77649 12.0919 7.47885 13.1254 5.51745 13.1254C3.9446 13.1254 2.56976 12.6669 2.56976 11.1167C2.56976 9.9122 3.53364 9.43186 5.24099 9.29722C8.1065 9.04978 9.16752 8.95516 9.77649 8.55124V10.0432Z"
              fill="white"
            />
            <path
              d="M33.1312 3.63867C29.3205 3.63867 27.572 6.1459 27.572 8.91877C27.572 11.3496 28.9655 14.1989 33.1312 14.1989C36.4936 14.1989 38.059 12.4777 38.6306 10.7164H37.1773C36.6468 12.2484 35.567 13.0126 33.1312 13.0126C30.5384 13.0126 29.0216 11.3314 29.0216 9.26446H38.8809C38.8846 4.77038 35.7426 3.63867 33.1312 3.63867ZM33.2283 4.82497C35.1748 4.82497 37.0203 5.74562 37.4313 8.19463H29.0253C29.3392 5.74562 31.3827 4.82497 33.2283 4.82497Z"
              fill="white"
            />
            <path
              d="M65.2009 12.8998C64.633 12.8998 64.3977 12.6123 64.3977 11.7899V6.58621C64.3977 4.67213 63.1386 3.63867 59.7239 3.63867C56.227 3.63867 55.0689 4.92322 54.8671 7.00833H56.2009C56.3018 5.39992 57.4001 4.82497 59.6978 4.82497C62.115 4.82497 63.0602 5.3235 63.0602 6.77908C63.0602 7.75431 62.6866 7.94353 59.4886 8.09637C57.247 8.19462 54.4001 8.53668 54.4001 11.2149C54.4001 13.4529 56.6193 14.1989 58.3678 14.1989C61.7077 14.1989 62.6903 12.8597 63.1386 12.2084H63.1797C63.1797 12.9944 63.2769 13.9696 64.7488 13.9696C65.1591 13.9592 65.5672 13.908 65.9668 13.8168V12.8597C65.7127 12.8779 65.4587 12.8998 65.2009 12.8998ZM63.0602 10.0432C63.0602 12.0919 60.7625 13.1254 58.8012 13.1254C57.2283 13.1254 55.8535 12.6669 55.8535 11.1167C55.8535 9.9122 56.8173 9.43186 58.5247 9.29722C61.3939 9.04978 62.4512 8.95516 63.0602 8.55124V10.0432Z"
              fill="white"
            />
            <path
              d="M23.7127 0.396484V6.0987H23.6716C23.2635 5.40045 22.6685 4.82316 21.95 4.4284C21.2316 4.03363 20.4166 3.83619 19.5919 3.85712C15.8559 3.85712 14.1411 6.31704 14.1411 9.03533C14.1411 11.4152 15.5085 14.2099 19.5919 14.2099C22.0353 14.2099 23.2681 12.7871 23.6716 11.961H23.7127V13.9115H25.1362L25.1175 0.407401L23.7127 0.396484ZM19.6031 13.0382C16.6965 13.0382 15.5795 10.9749 15.5795 9.03533C15.5795 6.56085 17.3503 5.0325 19.6031 5.0325C22.5097 5.0325 23.7987 7.03755 23.7987 9.03533C23.7875 11.5753 21.9606 13.0382 19.5919 13.0382H19.6031Z"
              fill="white"
            />
            <path
              d="M19.9726 27.7064C19.9726 25.4357 18.792 24.264 16.1656 24.264C13.3599 24.264 11.8095 25.8433 11.8095 28.2668V35.6211H9.99004V27.1824C9.99004 25.6068 8.82815 24.2713 6.35118 24.2713C3.73599 24.2713 1.83063 25.7268 1.83063 28.6052V35.6175H0V23.1614H1.82317V25.4539H1.868C2.63387 24.1403 4.00872 22.7793 6.934 22.7793C9.85928 22.7793 11.137 24.264 11.5031 25.5085C12.1196 24.264 13.9614 22.7793 16.5243 22.7793C20.1146 22.7793 21.7808 24.5515 21.7808 27.1096V35.6102H19.9651L19.9726 27.7064Z"
              fill="#B0BF3C"
            />
            <path
              d="M35.3876 28.9145C34.5731 29.4094 33.344 29.5113 29.7089 29.8461C27.5906 30.0353 26.3465 30.654 26.3465 32.1386C26.3465 34.0818 28.0688 34.6459 30.0339 34.6459C32.496 34.6459 35.3727 33.365 35.3727 30.7995L35.3876 28.9145ZM25.1099 26.9895C25.3826 24.3804 26.8322 22.7793 31.207 22.7793C35.481 22.7793 37.0576 24.0711 37.0576 26.4655V32.9683C37.0576 33.9945 37.349 34.3584 38.0626 34.3584C38.3839 34.3584 38.7014 34.3293 39.0265 34.3111V35.5156C38.5255 35.6331 38.0135 35.7001 37.4984 35.7157C35.6529 35.7157 35.5595 34.493 35.5296 33.5105H35.4922C34.9281 34.3256 33.6989 35.9959 29.5146 35.9959C27.3291 35.9959 24.5532 35.0643 24.5532 32.2624C24.5532 28.8854 28.1174 28.5033 30.9044 28.3614C34.887 28.1431 35.3876 27.9284 35.3876 26.7093C35.3876 24.9117 34.1547 24.2676 31.1585 24.2676C28.278 24.2676 26.9032 24.9954 26.7836 26.9932L25.1099 26.9895Z"
              fill="#B0BF3C"
            />
            <path
              d="M40.842 23.1616H42.6615V26.0109H42.7138C43.3713 24.5298 44.5818 22.7795 47.6042 22.7795C47.9675 22.7767 48.3302 22.8071 48.6876 22.8704V24.6899C48.1361 24.5969 47.5773 24.5507 47.0176 24.5516C45.0488 24.5516 42.6615 25.7234 42.6615 29.2823V35.6249H40.842V23.1616Z"
              fill="#B0BF3C"
            />
            <path
              d="M63.5603 31.15C63.2166 32.7766 61.7409 35.9825 57.0933 35.9825C52.4457 35.9825 49.894 33.2824 49.894 29.3778C49.894 25.6407 52.2477 22.7695 57.0933 22.7695C61.4681 22.7695 63.1718 25.4296 63.4445 27.2236H61.6437C61.0796 25.0875 59.3498 24.2542 57.0933 24.2542C53.4283 24.2542 51.7097 26.5031 51.7097 29.3778C51.7097 32.4709 53.6263 34.4978 57.0933 34.4978C59.7683 34.4978 61.2739 33.0896 61.7409 31.15H63.5603Z"
              fill="#B0BF3C"
            />
            <path
              d="M66.1943 18.373H67.9951V25.6072H68.0511C68.5966 24.2899 70.5056 22.7798 73.3301 22.7798C77.5854 22.7798 78.8631 24.934 78.8631 27.8998V35.6107H77.0436V27.605C77.0436 25.1415 75.4932 24.279 73.1881 24.279C69.6202 24.279 67.9951 26.0476 67.9951 28.875V35.6252H66.1831L66.1943 18.373Z"
              fill="#B0BF3C"
            />
            <path
              d="M94.1879 28.4784C93.6798 25.4108 91.3598 24.2645 88.9314 24.2645C86.5927 24.2645 84.0746 25.4363 83.6674 28.4784H94.1879ZM95.6823 31.6334C94.9351 33.8386 93.0335 36.0001 88.8007 36.0001C83.5703 36.0001 81.8442 32.4376 81.8442 29.3954C81.8442 25.9202 84.0298 22.7871 88.8007 22.7871C92.0921 22.7871 95.9999 24.1699 95.9999 29.8212H83.6861C83.6861 32.4157 85.584 34.5154 88.8268 34.5154C91.8754 34.5154 93.2502 33.5329 93.8965 31.6406L95.6823 31.6334Z"
              fill="#B0BF3C"
            />
            <path
              d="M44.7382 3.49351C44.4994 3.76125 44.2903 4.05277 44.1143 4.36322C43.94 4.67227 43.7986 4.99781 43.6921 5.33481C43.6398 5.5022 43.595 5.67323 43.5576 5.8479C43.539 5.93523 43.524 6.02621 43.5053 6.11355C43.4866 6.20088 43.4754 6.29185 43.468 6.37918C43.4288 6.72963 43.4213 7.08275 43.4455 7.43447C43.4828 8.10824 43.6591 8.76778 43.964 9.37364C44.2689 9.97951 44.6961 10.5192 45.2201 10.9606C45.4756 11.1687 45.7552 11.3471 46.0533 11.4919C46.3509 11.647 46.6654 11.7691 46.991 11.8558C47.6025 12.0183 48.2475 12.0183 48.859 11.8558C49.4813 11.6857 50.0469 11.3595 50.4991 10.9097C50.612 10.7944 50.7181 10.6729 50.8167 10.5458C50.9141 10.4162 51.0039 10.2813 51.0856 10.1418C51.1666 10.0017 51.2366 9.85568 51.2949 9.70517C51.3285 9.62876 51.3546 9.55233 51.3845 9.47228C51.4144 9.39222 51.4294 9.32672 51.448 9.25394C51.4847 9.10452 51.5085 8.95236 51.519 8.79907C51.5302 8.64522 51.5302 8.49079 51.519 8.33693C51.4883 8.01786 51.4011 7.70637 51.2612 7.41628C51.1197 7.11486 50.9264 6.83919 50.6896 6.60116C50.4456 6.355 50.1616 6.14967 49.849 5.99345C49.6885 5.91531 49.5224 5.84843 49.3521 5.79332C49.182 5.7421 49.0066 5.70915 48.8291 5.69506C48.6478 5.67691 48.465 5.67691 48.2836 5.69506C48.0969 5.71894 47.913 5.76038 47.7345 5.81879C47.3583 5.94586 47.0135 6.14786 46.722 6.41193C46.4052 6.695 46.151 7.03798 45.9748 7.41992C46.0049 6.97437 46.1418 6.5419 46.3746 6.15721C46.4955 5.95627 46.6397 5.76947 46.8042 5.60045C47.3271 5.07014 48.018 4.72662 48.7656 4.62521C49.0245 4.59185 49.2862 4.58575 49.5464 4.60702C49.799 4.62915 50.049 4.67299 50.2936 4.73803C50.7981 4.87513 51.2737 5.09812 51.6984 5.39668C52.078 5.66703 52.4131 5.99221 52.6921 6.36099L51.7469 0.946252C51.7405 0.905471 51.7305 0.865303 51.717 0.826172L51.2874 0.851636C50.446 0.913128 49.612 1.04832 48.7955 1.25556C48.0063 1.4502 47.2446 1.73862 46.5277 2.11435C45.852 2.46711 45.2461 2.9341 44.7382 3.49351Z"
              fill="#B0BF3C"
            />
            <path
              d="M46.8525 16.9829V18.2857C46.8523 18.4692 46.7836 18.6465 46.6592 18.7844C46.5348 18.9223 46.3632 19.0115 46.1763 19.0353L43.1875 19.552C43.1303 19.5584 43.078 19.5864 43.0417 19.6299C43.0054 19.6734 42.988 19.7291 42.9933 19.7849C42.9911 19.8165 42.9958 19.8482 43.0072 19.8779C43.0187 19.9076 43.0365 19.9345 43.0595 19.9569C43.0825 19.9793 43.1101 19.9967 43.1406 20.0078C43.1711 20.0189 43.2036 20.0236 43.2361 20.0214H51.6682C51.7007 20.0236 51.7332 20.0189 51.7637 20.0078C51.7942 19.9967 51.8218 19.9793 51.8448 19.9569C51.8679 19.9345 51.8857 19.9076 51.8971 19.8779C51.9085 19.8482 51.9133 19.8165 51.9111 19.7849C51.9016 19.7332 51.8789 19.6847 51.8449 19.644C51.8109 19.6032 51.7668 19.5716 51.7168 19.552L48.7803 19.0353C48.5934 19.0115 48.4218 18.9223 48.2974 18.7844C48.173 18.6465 48.1043 18.4692 48.1041 18.2857V16.8556V14.876C49.2212 14.7663 50.2936 14.3914 51.2274 13.7843C51.1041 13.8316 50.9771 13.8789 50.8538 13.9189C49.7946 14.2572 48.659 14.2975 47.5773 14.0354C47.0597 13.9105 46.5583 13.7286 46.0829 13.4932C45.6047 13.2554 45.1571 12.9635 44.7492 12.6235C43.9399 11.9448 43.3009 11.0946 42.8812 10.1381C42.6738 9.67041 42.5185 9.18243 42.4179 8.68252C42.3197 8.18863 42.2734 7.6863 42.2797 7.18328C42.2853 6.68799 42.3404 6.19441 42.4441 5.7095C42.5431 5.22591 42.6907 4.75299 42.8849 4.29759C43.0845 3.83813 43.3351 3.4013 43.6321 2.99486C43.9211 2.58768 44.2571 2.21407 44.6333 1.88134C45.3632 1.24669 46.21 0.752754 47.129 0.425769C47.6437 0.240524 48.1736 0.0980912 48.7131 8.34635e-06H42.7355C42.5421 -0.000847554 42.3545 0.064149 42.2051 0.183754C42.0557 0.303359 41.9539 0.47006 41.9173 0.655024L40.7965 7.03771C40.4903 8.71112 40.8467 10.4354 41.794 11.8629C42.3563 12.6929 43.1027 13.3893 43.9782 13.9007C44.8538 14.4121 45.836 14.7255 46.8525 14.8178"
              fill="white"
            />
          </svg>
        </span>
      ) : (
        <svg
          className="header__logo header__logo-desktop"
          width="293"
          height="38"
          viewBox="0 0 293 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_5107:80953)">
            <path
              d="M17.2716 24.2748V15.6028C17.2716 12.4282 15.0994 10.6875 9.19605 10.6875C3.15766 10.6875 1.15424 12.8039 0.754682 16.3013H3.06199C3.23082 13.624 5.13294 12.6663 9.10039 12.6663C13.2704 12.6663 14.9362 13.497 14.9362 15.9203C14.9362 17.5446 14.289 17.8674 8.74585 18.1214C4.87408 18.2801 -0.0444336 18.8515 -0.0444336 23.3171C-0.0444336 27.0526 3.79358 28.296 6.80997 28.296C12.5782 28.296 14.2721 26.0632 15.0544 24.9785H15.1613C15.1613 26.2854 15.3301 27.9097 17.8738 27.9097C18.5826 27.8912 19.2877 27.8061 19.9785 27.6558V26.0684C19.5396 26.1002 19.095 26.1319 18.656 26.1319C17.6768 26.1266 17.2716 25.6558 17.2716 24.2748ZM14.9643 21.3753C14.9643 24.788 10.9913 26.5076 7.60346 26.5076C4.88533 26.5076 2.53863 25.7457 2.53863 23.1584C2.53863 21.1531 4.22691 20.3542 7.15325 20.1002C12.1055 19.6822 13.9401 19.5235 14.9925 18.8515L14.9643 21.3753Z"
              fill="white"
            />
            <path
              d="M55.3023 10.6875C48.718 10.6875 45.7017 14.8674 45.7017 19.4917C45.7017 23.5446 48.1103 28.296 55.3023 28.296C61.1325 28.296 63.8168 25.423 64.796 22.4759H62.2861C61.3745 25.0261 59.5061 26.3013 55.3023 26.3013C50.8002 26.3013 48.2116 23.497 48.2116 20.0526H65.2462C65.2462 12.5711 59.81 10.6875 55.3023 10.6875ZM55.4711 12.6663C58.8477 12.6663 62.016 14.1954 62.7307 18.2801H48.2116C48.7518 14.1954 52.2465 12.6663 55.4711 12.6663Z"
              fill="white"
            />
            <path
              d="M110.7 26.1267C109.715 26.1267 109.31 25.6452 109.31 24.2748V15.6028C109.31 12.4282 107.138 10.6875 101.234 10.6875C95.1959 10.6875 93.1925 12.8039 92.8211 16.3013H95.1284C95.2972 13.624 97.1993 12.6663 101.167 12.6663C105.337 12.6663 107.003 13.497 107.003 15.9203C107.003 17.5446 106.355 17.8674 100.812 18.1214C96.9405 18.2801 92.022 18.8515 92.022 23.3171C92.022 27.0526 95.8544 28.296 98.8764 28.296C104.645 28.296 106.339 26.0632 107.121 24.9785H107.188C107.188 26.2854 107.357 27.9097 109.901 27.9097C110.61 27.8909 111.315 27.8058 112.006 27.6558V26.0684C111.578 26.0949 111.139 26.1267 110.7 26.1267ZM107.003 21.3647C107.003 24.7774 103.03 26.497 99.6417 26.497C96.9236 26.497 94.5488 25.7351 94.5488 23.1478C94.5488 21.1425 96.237 20.3436 99.1634 20.0896C104.116 19.6716 105.95 19.5129 107.003 18.8409V21.3647Z"
              fill="white"
            />
            <path
              d="M39.0329 5.28125V14.8051H38.9653C38.2615 13.638 37.2337 12.6726 35.9918 12.0119C34.7499 11.3512 33.3405 11.02 31.914 11.0537C25.4591 11.0537 22.499 15.149 22.499 19.6834C22.499 23.6516 24.8626 28.313 31.914 28.313C36.1347 28.313 38.2675 25.9373 38.9653 24.5617H39.0329L39.061 27.8104H41.5259L41.4921 5.29712L39.0329 5.28125ZM31.914 26.3553C26.8885 26.3553 24.9639 22.9162 24.9639 19.6675C24.9639 15.5405 28.0197 12.9744 31.914 12.9744C36.9338 12.9744 39.1623 16.3183 39.1623 19.6675C39.1623 23.9162 35.9827 26.3553 31.914 26.3553Z"
              fill="white"
            />
            <path
              d="M161.664 16.0255C161.664 12.2425 159.621 10.2848 155.085 10.2848C150.24 10.2848 147.561 12.9303 147.561 16.9409V29.2054H144.421V15.1525C144.421 12.507 142.417 10.2848 138.135 10.2848C133.633 10.2848 130.324 12.7186 130.324 17.5123V29.2054H127.178V8.44351H130.324V12.2689H130.402C131.725 10.0784 134.105 7.80859 139.153 7.80859C144.201 7.80859 146.418 10.2848 147.032 12.3589C148.09 10.2848 151.275 7.80859 155.698 7.80859C161.889 7.80859 164.781 10.761 164.781 15.0308V29.2054H161.647L161.664 16.0255Z"
              fill="#B0BF3C"
            />
            <path
              d="M188.287 18.0361C186.88 18.8668 184.764 19.0308 178.478 19.5917C174.821 19.9038 172.699 20.9409 172.699 23.4171C172.699 26.6552 175.67 27.597 179.069 27.597C183.318 27.597 188.287 25.4805 188.287 21.179V18.0361ZM170.532 14.8298C171.005 10.4753 173.509 7.80859 181.067 7.80859C188.451 7.80859 191.169 9.96203 191.169 13.9567V24.8192C191.169 26.5282 191.681 27.1366 192.913 27.1366C193.476 27.1366 194.011 27.0837 194.573 27.0573V29.0414C193.708 29.2349 192.823 29.3448 191.934 29.3694C188.749 29.3694 188.586 27.3377 188.557 25.6975H188.473C187.5 27.0573 185.378 29.8403 178.158 29.8403C174.382 29.8403 169.587 28.2901 169.587 23.6181C169.587 17.9832 175.738 17.3483 180.578 17.1102C187.46 16.7451 188.31 16.3853 188.31 14.3589C188.31 11.3589 186.183 10.2848 180.994 10.2848C176.019 10.2848 173.644 11.5176 173.436 14.8298H170.532Z"
              fill="#B0BF3C"
            />
            <path
              d="M197.714 8.44498H200.871V13.2069H200.961C202.086 10.7413 204.18 7.82064 209.402 7.82064C210.031 7.81571 210.658 7.86709 211.276 7.97408V11.0111C210.324 10.8507 209.357 10.7711 208.389 10.773C204.985 10.773 200.865 12.7307 200.865 18.6407V29.2228H197.725L197.714 8.44498Z"
              fill="#B0BF3C"
            />
            <path
              d="M236.949 21.7821C236.358 24.4964 233.809 29.8403 225.784 29.8403C217.759 29.8403 213.347 25.3377 213.347 18.8298C213.347 12.597 217.416 7.80859 225.784 7.80859C233.342 7.80859 236.28 12.2425 236.78 15.216H233.646C232.672 11.6552 229.707 10.2636 225.767 10.2636C219.442 10.3112 216.482 14.0149 216.482 18.8298C216.482 23.9832 219.796 27.3641 225.784 27.3641C230.404 27.3641 233.004 25.0149 233.809 21.7821H236.949Z"
              fill="#B0BF3C"
            />
            <path
              d="M241.502 0.460938H244.631V12.5244H244.727C245.672 10.3339 248.97 7.81014 253.849 7.81014C261.199 7.81014 263.416 11.4027 263.416 16.3498V29.207H260.276V15.8313C260.276 11.7255 257.597 10.2863 253.618 10.2863C247.428 10.2863 244.614 13.2334 244.614 17.9477V29.207H241.502V0.460938Z"
              fill="#B0BF3C"
            />
            <path
              d="M289.86 17.3121C288.976 12.201 284.975 10.3121 280.783 10.3121C276.736 10.3121 272.414 12.2698 271.689 17.3386L289.86 17.3121ZM292.454 22.6031C291.177 26.2751 287.873 29.8677 280.569 29.8677C271.525 29.8677 268.548 23.9312 268.548 18.8571C268.548 13.0634 272.324 7.83594 280.569 7.83594C286.253 7.83594 293 10.1428 293 19.5661H271.689C271.689 23.8941 274.969 27.3915 280.569 27.3915C285.831 27.3915 288.205 25.7566 289.325 22.6296L292.454 22.6031Z"
              fill="#B0BF3C"
            />
            <path d="M120.801 0H119.372V37.9947H120.801V0Z" fill="#B0BF3C" />
            <path
              d="M75.3757 10.4614C74.9463 10.9014 74.5689 11.384 74.2502 11.9005C73.9468 12.4129 73.7016 12.9538 73.5186 13.5143C73.4286 13.8 73.3498 14.0857 73.2879 14.3767C73.2541 14.5196 73.2316 14.6677 73.1978 14.8159C73.1641 14.964 73.1472 15.1122 73.1359 15.2603C73.0666 15.8436 73.0534 16.4315 73.0965 17.0169C73.1591 18.1402 73.4625 19.2399 73.9886 20.2501C74.5147 21.2603 75.2526 22.16 76.1579 22.8952C76.6006 23.2421 77.084 23.5404 77.5986 23.7841C78.1169 24.0326 78.6621 24.2277 79.225 24.3661C80.2845 24.6359 81.4013 24.6359 82.4608 24.3661C83.5303 24.0776 84.5005 23.5303 85.2746 22.7788C85.4757 22.5912 85.6637 22.3915 85.8374 22.181C86.0006 21.964 86.1581 21.7418 86.2988 21.509C86.4377 21.2734 86.5599 21.0295 86.6646 20.7788C86.7209 20.6519 86.7659 20.5249 86.8166 20.3926C86.8672 20.2603 86.8953 20.1492 86.9235 20.0275C86.9898 19.7795 87.0313 19.5262 87.0473 19.2709C87.0669 19.012 87.0669 18.7521 87.0473 18.4931C86.9928 17.9633 86.8425 17.4462 86.6027 16.964C86.3587 16.4616 86.0257 16.0018 85.6179 15.6042C85.1966 15.192 84.7037 14.8502 84.1603 14.5937C83.8861 14.4592 83.5999 14.3477 83.3049 14.2603C83.0119 14.1745 82.7101 14.1178 82.4045 14.091C82.0901 14.062 81.7735 14.062 81.4591 14.091C81.1354 14.1289 80.8167 14.1981 80.508 14.2974C79.859 14.5081 79.2645 14.8452 78.7635 15.2868C78.2248 15.7593 77.794 16.33 77.4973 16.964C77.5447 16.2167 77.7821 15.4909 78.1895 14.8476C78.3966 14.5134 78.6439 14.2027 78.9267 13.9217C79.8261 13.0389 81.0156 12.4667 82.3032 12.2974C82.7489 12.2399 83.1998 12.2275 83.6482 12.2603C84.0916 12.2965 84.5305 12.3709 84.9595 12.4825C85.8307 12.712 86.6518 13.0846 87.3849 13.5831C88.0379 14.0289 88.6164 14.5639 89.1014 15.1704L87.4131 6.17566C87.4022 6.10743 87.3852 6.04018 87.3624 5.97461L86.6196 6.01694C85.166 6.11856 83.7251 6.34345 82.3145 6.68889C80.9435 7.01211 79.6203 7.49373 78.3752 8.12276C77.2364 8.72978 76.2209 9.52153 75.3757 10.4614Z"
              fill="#B0BF3C"
            />
            <path
              d="M79.0059 32.9372V35.1065C79.0049 35.4124 78.8866 35.7076 78.6725 35.938C78.4585 36.1684 78.1633 36.3184 77.841 36.3605L72.6805 37.2176C72.5819 37.2295 72.4921 37.2771 72.4302 37.3502C72.3682 37.4232 72.3389 37.5161 72.3485 37.6091C72.3452 37.6614 72.3537 37.7136 72.3735 37.7625C72.3933 37.8114 72.4238 37.8558 72.4632 37.8928C72.5025 37.9298 72.5498 37.9586 72.6018 37.9771C72.6538 37.9957 72.7094 38.0037 72.7649 38.0007H87.3235C87.379 38.0037 87.4346 37.9957 87.4866 37.9771C87.5387 37.9586 87.5859 37.9298 87.6252 37.8928C87.6646 37.8558 87.6951 37.8114 87.7149 37.7625C87.7347 37.7136 87.7432 37.6614 87.7399 37.6091C87.7233 37.5232 87.6842 37.4424 87.6263 37.3741C87.5684 37.3058 87.4933 37.252 87.4079 37.2176L82.3431 36.3605C82.0208 36.3184 81.7255 36.1684 81.5115 35.938C81.2975 35.7076 81.1791 35.4124 81.1782 35.1065V32.7255V29.4239C83.1068 29.2456 84.9596 28.6262 86.575 27.6197C86.3555 27.7044 86.1361 27.7784 85.9166 27.8472C84.0864 28.4119 82.1237 28.4779 80.2552 28.0377C79.3583 27.8303 78.4898 27.5267 77.6666 27.1329C76.84 26.7386 76.0672 26.2519 75.3649 25.6832C73.967 24.5525 72.8638 23.1351 72.1403 21.5403C71.7879 20.7622 71.522 19.9519 71.3468 19.1224C71.1752 18.2998 71.0922 17.4632 71.0992 16.625C71.1125 15.7965 71.2086 14.971 71.3862 14.1594C71.5594 13.3554 71.8138 12.5688 72.1459 11.8102C72.4853 11.0464 72.9077 10.3177 73.4065 9.63559C73.9076 8.96001 74.487 8.33891 75.1341 7.78373C76.3931 6.71521 77.8583 5.88361 79.4505 5.334C80.3379 5.02721 81.2508 4.79006 82.1799 4.625H71.8476C71.5134 4.62432 71.1893 4.73336 70.9314 4.93331C70.6736 5.13327 70.498 5.41159 70.4351 5.72025L68.5217 16.3605C67.9933 19.1574 68.617 22.0385 70.2663 24.4187C71.2352 25.8063 72.5238 26.971 74.0368 27.8265C75.5497 28.682 77.2481 29.2063 79.0059 29.3605"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_5107:80953">
              <rect width="293" height="38" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  )
}

export default Logo