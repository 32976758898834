const IconMessageWhite = () => {
    return (
        <i>
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.69976 3.75H20.2998C21.3723 3.75 22.2498 4.67812 22.2498 5.8125V18.1875C22.2498 19.3219 21.3723 20.25 20.2998 20.25H4.69976C3.62726 20.25 2.74976 19.3219 2.74976 18.1875V5.8125C2.74976 4.67812 3.62726 3.75 4.69976 3.75Z"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.2498 5.8125L12.4998 13.0313L2.74976 5.8125"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </i>
    )
}
  
export default IconMessageWhite