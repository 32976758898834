import React from 'react'

const LogoVertical = () => {
  return (
    <svg
      width="129"
      height="48"
      viewBox="0 0 129 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mb-8"
    >
      <path
        d="M14.9344 15.7195V8.78148C14.9344 6.22946 13.2426 4.85156 8.65409 4.85156C3.95514 4.85156 2.39887 6.56423 2.12777 9.34429H3.92C4.05555 7.19981 5.53151 6.43324 8.61896 6.43324C11.8671 6.43324 13.1372 7.09792 13.1372 9.03862C13.1372 10.3389 12.6351 10.5912 8.33781 10.795C5.32567 10.926 1.50024 11.382 1.50024 14.9529C1.50024 17.9367 4.48227 18.9313 6.83174 18.9313C11.3198 18.9313 12.6351 17.1459 13.2426 16.2774H13.2978C13.2978 17.3254 13.4283 18.6257 15.4063 18.6257C15.9576 18.6115 16.5059 18.5432 17.0429 18.4219V17.1459C16.7015 17.1702 16.3602 17.1993 16.0138 17.1993C15.2507 17.1993 14.9344 16.816 14.9344 15.7195ZM13.1372 13.3906C13.1372 16.1222 10.0497 17.5001 7.41409 17.5001C5.30057 17.5001 3.45313 16.8888 3.45313 14.8219C3.45313 13.216 4.74835 12.5756 7.0426 12.396C10.8931 12.0661 12.3189 11.94 13.1372 11.4014V13.3906Z"
        fill="#4F285D"
      />
      <path
        d="M44.5201 4.85156C39.3994 4.85156 37.0499 8.19442 37.0499 11.8915C37.0499 15.1324 38.9225 18.9313 44.5201 18.9313C49.0383 18.9313 51.1418 16.6365 51.9099 14.2882H49.957C49.2441 16.3308 47.7932 17.3497 44.5201 17.3497C41.036 17.3497 38.9978 15.1082 38.9978 12.3524H52.2462C52.2512 6.36045 48.0292 4.85156 44.5201 4.85156ZM44.6506 6.43324C47.2661 6.43324 49.7461 7.66073 50.2984 10.926H39.0028C39.4245 7.66073 42.1706 6.43324 44.6506 6.43324Z"
        fill="#4F285D"
      />
      <path
        d="M87.6138 17.1993C86.8507 17.1993 86.5345 16.816 86.5345 15.7195V8.78148C86.5345 6.22946 84.8426 4.85156 80.2541 4.85156C75.5552 4.85156 73.9989 6.56423 73.7278 9.34429H75.52C75.6556 7.19981 77.1315 6.43324 80.219 6.43324C83.4671 6.43324 84.7372 7.09792 84.7372 9.03862C84.7372 10.3389 84.2352 10.5912 79.9378 10.795C76.9257 10.926 73.1003 11.382 73.1003 14.9529C73.1003 17.9367 76.0823 18.9313 78.4318 18.9313C82.9199 18.9313 84.2402 17.1459 84.8426 16.2774H84.8979C84.8979 17.3254 85.0284 18.6257 87.0064 18.6257C87.5576 18.6118 88.106 18.5435 88.643 18.4219V17.1459C88.3016 17.1702 87.9602 17.1993 87.6138 17.1993ZM84.7372 13.3906C84.7372 16.1222 81.6498 17.5001 79.0141 17.5001C76.9006 17.5001 75.0532 16.8888 75.0532 14.8219C75.0532 13.216 76.3484 12.5756 78.6426 12.396C82.4982 12.0661 83.9189 11.94 84.7372 11.4014V13.3906Z"
        fill="#4F285D"
      />
      <path
        d="M31.8641 0.529297V8.13199H31.8088C31.2604 7.20102 30.4609 6.43133 29.4954 5.905C28.53 5.37866 27.4348 5.11542 26.3267 5.14332C21.3065 5.14332 19.0022 8.4231 19.0022 12.0474C19.0022 15.2204 20.8396 18.9465 26.3267 18.9465C29.61 18.9465 31.2667 17.0495 31.8088 15.9482H31.8641V18.5487H33.7768L33.7517 0.543852L31.8641 0.529297ZM26.3418 17.3843C22.436 17.3843 20.935 14.6333 20.935 12.0474C20.935 8.74817 23.3146 6.71044 26.3418 6.71044C30.2475 6.71044 31.9795 9.38375 31.9795 12.0474C31.9645 15.4339 29.5096 17.3843 26.3267 17.3843H26.3418Z"
        fill="#4F285D"
      />
      <path
        d="M26.8382 36.9404C26.8382 33.9129 25.2518 32.3506 21.7226 32.3506C17.9524 32.3506 15.869 34.4563 15.869 37.6875V47.4929H13.4241V36.2417C13.4241 34.1409 11.8628 32.3603 8.53441 32.3603C5.02024 32.3603 2.45992 34.301 2.45992 38.1387V47.4881H0V30.8805H2.44988V33.9371H2.51012C3.53927 32.1857 5.38672 30.3711 9.31757 30.3711C13.2484 30.3711 14.9653 32.3506 15.4573 34.0099C16.2857 32.3506 18.7606 30.3711 22.2045 30.3711C27.029 30.3711 29.268 32.7339 29.268 36.1447V47.4784H26.8282L26.8382 36.9404Z"
        fill="#B0BF3C"
      />
      <path
        d="M47.5521 38.5511C46.4577 39.211 44.8061 39.3468 39.9214 39.7932C37.0749 40.0455 35.4031 40.8703 35.4031 42.8498C35.4031 45.4406 37.7175 46.1926 40.3581 46.1926C43.6665 46.1926 47.532 44.4848 47.532 41.0644L47.5521 38.5511ZM33.7415 35.9846C34.1079 32.5059 36.0558 30.3711 41.9345 30.3711C47.6776 30.3711 49.7962 32.0935 49.7962 35.2859V43.956C49.7962 45.3242 50.1878 45.8094 51.1466 45.8094C51.5784 45.8094 52.0051 45.7705 52.4419 45.7463V47.3522C51.7687 47.5089 51.0806 47.5983 50.3886 47.6191C47.9086 47.6191 47.783 45.9889 47.7429 44.6789H47.6927C46.9346 45.7657 45.283 47.9926 39.6603 47.9926C36.7235 47.9926 32.9934 46.7506 32.9934 43.0148C32.9934 38.5123 37.7827 38.0029 41.5278 37.8137C46.8794 37.5226 47.5521 37.2363 47.5521 35.611C47.5521 33.2142 45.8955 32.3555 41.8692 32.3555C37.9986 32.3555 36.1512 33.3258 35.9905 35.9894L33.7415 35.9846Z"
        fill="#B0BF3C"
      />
      <path
        d="M54.8815 30.8808H57.3264V34.6797H57.3967C58.2802 32.705 59.9068 30.3713 63.9682 30.3713C64.4563 30.3676 64.9438 30.4082 65.424 30.4926V32.9185C64.6829 32.7945 63.9321 32.7328 63.18 32.7341C60.5343 32.7341 57.3264 34.2964 57.3264 39.0414V47.498H54.8815V30.8808Z"
        fill="#B0BF3C"
      />
      <path
        d="M85.4092 41.531C84.9474 43.6997 82.9644 47.9741 76.7192 47.9741C70.474 47.9741 67.0452 44.3741 67.0452 39.1682C67.0452 34.1855 70.2079 30.3574 76.7192 30.3574C82.5979 30.3574 84.8871 33.904 85.2536 36.296H82.8338C82.0758 33.448 79.7514 32.3369 76.7192 32.3369C71.7943 32.3369 69.485 35.3353 69.485 39.1682C69.485 43.2922 72.0604 45.9946 76.7192 45.9946C80.3137 45.9946 82.3368 44.117 82.9644 41.531H85.4092Z"
        fill="#B0BF3C"
      />
      <path
        d="M88.9486 24.4961H91.3684V34.1414H91.4437C92.1767 32.385 94.742 30.3716 98.5373 30.3716C104.255 30.3716 105.972 33.2438 105.972 37.198V47.4788H103.527V36.805C103.527 33.5203 101.444 32.3705 98.3465 32.3705C93.5522 32.3705 91.3684 34.7284 91.3684 38.4982V47.4982H88.9336L88.9486 24.4961Z"
        fill="#B0BF3C"
      />
      <path
        d="M126.565 37.971C125.882 33.8809 122.765 32.3526 119.502 32.3526C116.359 32.3526 112.975 33.9149 112.428 37.971H126.565ZM128.573 42.1774C127.569 45.1176 125.014 47.9995 119.326 47.9995C112.298 47.9995 109.978 43.2497 109.978 39.1936C109.978 34.5602 112.915 30.3828 119.326 30.3828C123.749 30.3828 129 32.2265 129 39.7613H112.453C112.453 43.2206 115.004 46.02 119.361 46.02C123.458 46.02 125.305 44.71 126.174 42.1871L128.573 42.1774Z"
        fill="#B0BF3C"
      />
      <path
        d="M60.117 4.65789C59.7962 5.01487 59.5152 5.40355 59.2786 5.81746C59.0445 6.22952 58.8544 6.66356 58.7114 7.11287C58.6411 7.33605 58.5808 7.56409 58.5306 7.79697C58.5055 7.91341 58.4854 8.03471 58.4603 8.15115C58.4352 8.2676 58.4202 8.38888 58.4101 8.50532C58.3575 8.97257 58.3474 9.44338 58.38 9.91233C58.43 10.8107 58.667 11.69 59.0767 12.4978C59.4864 13.3056 60.0605 14.0252 60.7646 14.6137C61.1079 14.8912 61.4837 15.1289 61.8842 15.322C62.284 15.5289 62.7067 15.6916 63.1442 15.8072C63.9659 16.0238 64.8327 16.0238 65.6544 15.8072C66.4906 15.5805 67.2505 15.1455 67.8582 14.5458C68.01 14.3921 68.1525 14.2301 68.285 14.0606C68.4159 13.8878 68.5365 13.708 68.6464 13.522C68.7552 13.3351 68.8492 13.1405 68.9275 12.9398C68.9727 12.8379 69.0079 12.736 69.048 12.6293C69.0882 12.5226 69.1083 12.4352 69.1334 12.3382C69.1827 12.139 69.2146 11.9361 69.2288 11.7317C69.2438 11.5266 69.2438 11.3207 69.2288 11.1156C69.1875 10.6901 69.0703 10.2749 68.8824 9.88807C68.6922 9.48619 68.4324 9.11865 68.1143 8.80129C67.7863 8.47308 67.4047 8.19932 66.9847 7.99104C66.769 7.88684 66.5459 7.79767 66.317 7.7242C66.0884 7.65591 65.8527 7.61197 65.6142 7.59319C65.3705 7.56899 65.1249 7.56899 64.8812 7.59319C64.6303 7.62503 64.3832 7.68029 64.1433 7.75816C63.6378 7.92758 63.1745 8.1969 62.7828 8.54899C62.3571 8.9264 62.0155 9.38369 61.7787 9.89293C61.8192 9.29888 62.0031 8.72227 62.3159 8.20937C62.4784 7.94146 62.6721 7.6924 62.8932 7.46706C63.5958 6.76 64.5242 6.30199 65.5289 6.16678C65.8767 6.12229 66.2284 6.11417 66.5781 6.14252C66.9174 6.17202 67.2534 6.23048 67.5821 6.31719C68.26 6.49999 68.8991 6.79729 69.4697 7.19536C69.9799 7.55582 70.4302 7.98937 70.8051 8.48107L69.535 1.26166C69.5263 1.20729 69.5129 1.15373 69.4948 1.10156L68.9175 1.13551C67.7869 1.2175 66.6662 1.39775 65.569 1.67407C64.5085 1.93357 63.4851 2.31812 62.5217 2.81907C61.6137 3.28941 60.7995 3.91204 60.117 4.65789Z"
        fill="#B0BF3C"
      />
      <path
        d="M62.9581 22.6431V24.38C62.9579 24.6248 62.8655 24.8611 62.6984 25.045C62.5312 25.2289 62.3006 25.3477 62.0495 25.3795L58.0333 26.0685C57.9564 26.077 57.8861 26.1143 57.8373 26.1723C57.7886 26.2304 57.7652 26.3045 57.7723 26.379C57.7693 26.4211 57.7757 26.4634 57.791 26.503C57.8064 26.5425 57.8303 26.5785 57.8612 26.6083C57.8922 26.6382 57.9293 26.6613 57.9703 26.6762C58.0112 26.691 58.0549 26.6972 58.0986 26.6943H69.4292C69.4729 26.6972 69.5166 26.691 69.5575 26.6762C69.5985 26.6613 69.6357 26.6382 69.6666 26.6083C69.6975 26.5785 69.7214 26.5425 69.7368 26.503C69.7521 26.4634 69.7585 26.4211 69.7556 26.379C69.7429 26.3101 69.7123 26.2454 69.6666 26.1911C69.621 26.1368 69.5617 26.0946 69.4945 26.0685L65.5486 25.3795C65.2975 25.3477 65.0669 25.2289 64.8997 25.045C64.7326 24.8611 64.6402 24.6248 64.6399 24.38V22.4733V19.834C66.141 19.6877 67.582 19.1879 68.8369 18.3784C68.6712 18.4415 68.5005 18.5046 68.3348 18.558C66.9116 19.0089 65.3856 19.0627 63.9321 18.7132C63.2365 18.5467 62.5628 18.3041 61.924 17.9903C61.2814 17.6733 60.6799 17.2841 60.1318 16.8307C59.0443 15.9258 58.1856 14.7923 57.6217 13.517C57.343 12.8934 57.1343 12.2428 56.9991 11.5763C56.8672 10.9178 56.8049 10.248 56.8134 9.57737C56.8209 8.91702 56.8949 8.25893 57.0343 7.61241C57.1673 6.96764 57.3657 6.3371 57.6267 5.72993C57.8949 5.11733 58.2316 4.53491 58.6307 3.99301C59.0191 3.45012 59.4705 2.952 59.9761 2.50837C60.9568 1.6622 62.0948 1.00364 63.3297 0.567672C64.0213 0.320688 64.7334 0.130784 65.4582 1.11281e-05H57.4259C57.166 -0.00113003 56.9139 0.0855291 56.7131 0.244997C56.5124 0.404465 56.3756 0.626725 56.3264 0.873335L54.8204 9.38329C54.4088 11.6144 54.8878 13.9134 56.1607 15.8167C56.9163 16.9233 57.9193 17.8518 59.0958 18.5337C60.2723 19.2155 61.5922 19.6334 62.9581 19.7563"
        fill="#4F285D"
      />
    </svg>
  )
}

export default LogoVertical
