const IconWishlist = ({stroke = '#C4C6C8'}) => {
  return (
    <i>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2464 5.27503C19.7699 4.79155 19.2042 4.40802 18.5815 4.14635C17.9589 3.88468 17.2915 3.75 16.6175 3.75C15.9435 3.75 15.2761 3.88468 14.6534 4.14635C14.0308 4.40802 13.4651 4.79155 12.9886 5.27503L11.9998 6.27794L11.0109 5.27503C10.0485 4.29889 8.74311 3.75051 7.38202 3.75051C6.02092 3.75051 4.71557 4.29889 3.75313 5.27503C2.79069 6.25116 2.25 7.57508 2.25 8.95554C2.25 10.336 2.79069 11.6599 3.75313 12.6361L4.74198 13.639L11.9998 21L19.2575 13.639L20.2464 12.6361C20.7231 12.1528 21.1012 11.579 21.3592 10.9475C21.6172 10.316 21.75 9.63912 21.75 8.95554C21.75 8.27196 21.6172 7.59508 21.3592 6.96356C21.1012 6.33205 20.7231 5.75828 20.2464 5.27503V5.27503Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </i>
  );
};

export default IconWishlist;
