exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-[slug]-p-tsx": () => import("./../../../src/pages/[slug]/p.tsx" /* webpackChunkName: "component---src-pages-[slug]-p-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-institutional-about-us-tsx": () => import("./../../../src/pages/institutional/about-us.tsx" /* webpackChunkName: "component---src-pages-institutional-about-us-tsx" */),
  "component---src-pages-institutional-politics-tsx": () => import("./../../../src/pages/institutional/politics.tsx" /* webpackChunkName: "component---src-pages-institutional-politics-tsx" */),
  "component---src-pages-landing-page-collection-fixed-tsx": () => import("./../../../src/pages/landing-page-collection-fixed.tsx" /* webpackChunkName: "component---src-pages-landing-page-collection-fixed-tsx" */),
  "component---src-pages-landing-page-collection-tsx": () => import("./../../../src/pages/landing-page-collection.tsx" /* webpackChunkName: "component---src-pages-landing-page-collection-tsx" */),
  "component---src-pages-landing-page-custom-tsx": () => import("./../../../src/pages/landing-page-custom.tsx" /* webpackChunkName: "component---src-pages-landing-page-custom-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-s-tsx": () => import("./../../../src/pages/s.tsx" /* webpackChunkName: "component---src-pages-s-tsx" */),
  "component---src-pages-slug-tsx": () => import("./../../../src/pages/[...slug].tsx" /* webpackChunkName: "component---src-pages-slug-tsx" */),
  "component---src-pages-wishlist-tsx": () => import("./../../../src/pages/wishlist.tsx" /* webpackChunkName: "component---src-pages-wishlist-tsx" */)
}

