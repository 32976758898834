const IconNewsletter = () => {
  return (
    <svg
      width="58"
      height="57"
      viewBox="0 0 58 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5379 8.31158C23.1921 10.943 25.297 14.07 26.7314 17.5129C28.1659 20.9557 28.9017 24.6465 28.8965 28.373C21.329 28.3685 14.0729 25.3779 8.72188 20.0583C3.3709 14.7387 0.36273 7.52505 0.358155 0.00198048C4.10664 -0.00320289 7.81919 0.728256 11.2823 2.15431C14.7455 3.58036 17.891 5.6729 20.5379 8.31158Z"
        fill="#72537D"
      />
      <path
        d="M37.4397 8.48875L37.4394 8.48904C34.8092 11.0983 32.7229 14.1977 31.2998 17.6097C29.909 20.9444 29.1784 24.5137 29.1471 28.1219C36.561 28.0566 43.6571 25.1016 48.9056 19.887C54.1537 14.6728 57.1299 7.62141 57.2004 0.25284C53.5689 0.281539 49.9763 1.00615 46.6196 2.38778C43.1856 3.80124 40.066 5.87454 37.4397 8.48875Z"
        stroke="#72537D"
        strokeWidth="0.5"
      />
      <path
        d="M8.69948 36.7342C11.3444 34.0946 14.4881 32.0008 17.9498 30.5733C21.4115 29.1458 25.1229 28.4126 28.8706 28.416C28.8774 32.1441 28.1439 35.8368 26.7123 39.2825C25.2806 42.7281 23.1789 45.859 20.5276 48.4955C17.8764 51.1321 14.7277 53.2224 11.2621 54.6467C7.79657 56.071 4.0823 56.8013 0.332278 56.7956C0.326774 53.0683 1.06326 49.3768 2.4993 45.9337C3.93533 42.4906 6.04253 39.364 8.69948 36.7342V36.7342Z"
        fill="#72537D"
      />
      <path
        d="M49.0844 36.6826C51.739 39.3161 53.8445 42.4445 55.2803 45.8885C56.7162 49.3326 57.454 53.0245 57.4517 56.7527C49.8797 56.7527 42.6178 53.7629 37.2629 48.441C31.9079 43.119 28.8984 35.9006 28.8961 28.3731C32.6456 28.3696 36.3589 29.1017 39.8233 30.5277C43.2876 31.9536 46.4348 34.0452 49.0844 36.6826V36.6826Z"
        fill="#72537D"
      />
    </svg>
  )
}

export default IconNewsletter